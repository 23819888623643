import './App.css'
import React from 'react'
import NavLink from 'react-bootstrap/esm/NavLink'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Yhteystiedot from './Yhteystiedot'
//import VaraaPaivat from './VaraaPaivat'
import BackToTopBtn from './BackToTopBtn'
import Vuokrausehdot from './Vuokrausehdot'

const Tarjoukset = () => {
    return (
        <>
            <div className='divYht'>
                <h1 className='titleName'>TARJOUKSET</h1>
                <div className='divInfo'>
                    <div className='divInfo-item'>
                        <h3 className='tarjousTxt'>Toteuta unelmiesi lomamatka - vuokraa matkailuauto</h3>

                        {/* <h3 className='tarjousTxt3'>Vuokraa matkailuauto toukokuussa:</h3>
                    <h3 className='tarjousTxt4'><b></b></h3>
                    <h3 className='tarjousTxt5'>toukokuun viikko hinta - <b>750€</b></h3> */}

                        <h3 className='tarjousTxt3'>Tällä hetkellä meillä ei ole erityisiä tarjouksia, mutta älä anna sen estää unelmiesi toteuttamista!
                            Ota meihin yhteyttä ja kysy räätälöityä tarjousta, joka sopii juuri sinulle.
                            Autamme mielellämme löytämään parhaan mahdollisen ratkaisun seuraavalle matkallesi.</h3>
                        <h3 className='tarjousTxt2'>Kysyy tarjous!</h3>


                        <Router>
                            <NavLink href='/Yhteystiedot' className='tarjousYhtLink'>Ota yhteyttä!</NavLink>
                            <Switch>
                                <Route path="/Yhteystiedot">
                                    <Yhteystiedot />
                                </Route>
                            </Switch>
                            {/*<NavLink className='tarjousVaraaLink' href='/VaraaPaivat'>Varaa</NavLink>
                            <Switch>
                                <Route path="/VaraaPaivat">
                                    <VaraaPaivat />
                                </Route>
                            </Switch>*/}
                            <NavLink className='tarjousVaraaLink' href='/Vuokrausehdot'>Vuokrausehdot</NavLink>
                            <Switch>
                                <Route path="/Vuokrausehdot">
                                    < Vuokrausehdot />
                                </Route>
                            </Switch>
                        </Router>
                    </div>

                    <div className='divInfo-item'>
                        <div className='tarjousContainer-slider'>
                            <div className='tarjousPic'></div>
                        </div>
                    </div>
                </div>
                <BackToTopBtn />
            </div>
        </>
    )
}

export default Tarjoukset