import './App.css'
import React from 'react'
//import taustavideo from './img/taustavideo.mp4'

const Etusivu = () => {
    return (
        <div className='taustakuva'>
            {/*<video src={taustavideo} autoPlay loop muted playsInline className='taustaVideo' type="video/mp4" />*/}
            <h1 className='etuTitle'>MATKUSTA</h1>
            <h4 className='etuText'>vuokraamalla matkailuauto</h4>
        </div>
    )
}

export default Etusivu
